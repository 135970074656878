



















































































































































































































import Vue from 'vue'
import { ApiResponse, Product, Fee, Inventory, Invoice, Project } from '@/interfaces'
import { mapState } from 'vuex'
import { ElForm } from 'element-ui/types/form'
import { AxiosRequestConfig } from 'axios'
import InvoicesTable from '@/components/InvoicesTable.vue'
import NumberFormatOptions = Intl.NumberFormatOptions

interface EmolumentConfig {
  taux: number
  limit: number
}

interface EmolumentItem {
  [method: string]: EmolumentValues
}

interface EmolumentValues {
  value: number
  emoluments: number[]
}

interface Emoluments {
  config: EmolumentConfig[]
  total: EmolumentItem | null
  items: EmolumentItem[] | null
}

interface Structure {
  id: number | null
  name: string | null
  type: string | null
}

export default Vue.extend({
  components: { InvoicesTable },
  props: {
    project: {
      type: Object as () => Project,
      required: false,
      default: null,
    },
    inventory: {
      type: Object as () => Inventory,
      required: false,
      default: null,
    },
    projectStructure: {
      type: Object as () => Structure,
      required: false,
      default: null,
    },
    duePeriod: {
      type: Number,
      required: false,
      default: 30,
    },
  },
  data() {
    return {
      busy: false,
      loading: false,
      showModal: false,
      billingData: {
        state: '' as string | null,
        method: '' as string | null,
        invoices: [] as Invoice[],
      },
      emoluments: {
        total: null,
        items: null,
      } as Emoluments,
      feeList: [] as Fee[],
      products: [] as Product[],
      statesList: [
        { text: 'Emise', value: 'emise' },
        { text: 'Proforma', value: 'proforma' },
        { text: 'Comptabilisée', value: 'comptabilisee' },
      ] as Record<string, string | null>[],
      formRules: {},
      viewportWidth: 0,
      tableData: [] as Record<string, string>[],
      isJudiciary: false,
      //methodChanged: false,
    }
  },
  computed: {
    ...mapState(['user']),
    inventoryLabel1(): string {
      return this.inventory?.value1Label ?? 'Exploitation'
    },
    inventoryLabel2(): string {
      return this.inventory?.value2Label ?? 'Réalisation'
    },
    averageInventoryLabel(): string {
      return `Moyenne (${this.inventory?.value1Label} + ${this.inventory?.value2Label})`
    },
  },
  watch: {
    project(newVal) {
      if (Number.isInteger(newVal.id)) {
        this.billingData.invoices = newVal.invoices ?? []
        this.checkIsJudiciary()
        this.loadAllProducts()
      } else {
        this.resetFormData()
      }
    },
    inventory(newVal) {
      if (Number.isInteger(newVal.id)) {
        this.getInventoryFees()
        this.checkIsJudiciary()
        this.billingData.method = newVal.emolumentMethod
      } else {
        this.resetFormData()
      }
    },
  },
  mounted() {
    if (this.inventory && Number.isInteger(this.inventory?.id)) {
      this.getInventoryFees()
      this.billingData.invoices = this.project.invoices ?? []
      this.billingData.method = this.inventory.emolumentMethod ?? ''
    } else {
      this.resetFormData()
    }

    this.viewportWidth = window.innerWidth
    this.checkIsJudiciary()

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    checkIsJudiciary() {
      this.isJudiciary = this.project.structure?.type == 'judiciaire'
    },
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    nl2br(str: string): string {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    formatCurrency(number: number, isEmolument = false): string {
      let options = {
        style: 'currency',
        currency: 'EUR',
      } as NumberFormatOptions
      if (isEmolument) {
        options = {
          ...options,
          maximumSignificantDigits: 6,
        }
      }
      return Intl.NumberFormat('fr-FR', options).format(number).toString()
    },
    getInvoiceState(): Record<string, string | null> | null {
      return this.statesList.find((state) => state.value === this.billingData.state) ?? null
    },
    getInvoiceLabel(column: string): string | null {
      if (column === 'state' && this.getInvoiceState()) {
        return this.getInvoiceState()?.text ?? null
      }
      return null
    },
    getEmolumentLabel() {
      if (this.billingData.method === 'exploitation') {
        return this.inventoryLabel1
      } else if (this.billingData.method === 'realisation') {
        return this.inventoryLabel2
      }
      return this.averageInventoryLabel
    },
    updateEmolumentMethod() {
      //this.methodChanged = true
    },
    resetFormData() {
      this.billingData = {
        state: '',
        method: '',
        invoices: [],
      }
    },
    getInvoices() {
      if (!this.busy) {
        this.busy = true

        const loading = this.$loading({
          target: '#invoicesTable',
          text: 'Chargement des données...',
        })

        this.$api
          .get(`/project/${this.project.id}/invoices/list`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.billingData.invoices = apiResponse.data
          })
          .finally(() => {
            loading.close()
            this.busy = false
          })
      }
    },
    getInventoryFees(update = false) {
      if (!update) {
        this.setFees(this.inventory?.fees ?? [])
      } else {
        const id = this.inventory ? this.inventory.id : null

        this.busy = true
        this.$api
          .get(`/fee/inventory/${id}`, {
            params: {
              method: this.billingData.method,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.setFees(apiResponse.data ?? [])
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    setFees(fees: Fee[] | null) {
      this.feeList = []
      if (fees) {
        fees.forEach((fee: Fee, index: number) => {
          fee.position = index + 1
          this.feeList.push(fee)
        })
      }
    },
    submit: function (newState: string | null) {
      this.busy = true
      ;(this.$refs.myForm as ElForm).validate((valid: boolean) => {
        if (valid) {
          this.billingData.state = newState
        }
        this.busy = false
      })
    },
    addFee() {
      const listEditableFees = this.feeList?.filter((fee) => fee.isEditable === true)
      if (this.feeList.length > 0 && listEditableFees.length > 0) {
        this.feeList.forEach((fee, index) => {
          if (!fee.label) {
            this.feeList?.splice(index, 1)
          } else if (fee.isEditable) {
            this.saveFee(fee, index)
          }
        })
      }
      this.createNewFee()
    },
    createNewFee() {
      const newFee: Fee = {
        label: '',
        type: null,
        quantity: null,
        priceExcludingTaxes: '',
        tvaRate: '',
        accountingAccount: '',
        isEditable: true,
        position: this.feeList?.length + 1,
        opaque: null,
      }
      this.feeList?.push(newFee)
    },
    canEditFee(index: number) {
      const item = this.feeList ? this.feeList[index] : null
      return item?.isEditable
    },
    isBuyerFee(fee: Fee) {
      return fee.type === 'fixe_buyer' || fee.type === 'percent_buyer'
    },
    isSellerFee(fee: Fee) {
      return fee.type === 'fixe_seller' || fee.type === 'percent_seller' || fee.type === 'frais'
    },
    changeFeeState(index: number, state: string) {
      if (this.feeList) {
        const hasEditableItem = this.feeList?.filter((fee) => fee.isEditable === true)
        if (hasEditableItem.length > 0) {
          this.feeList?.forEach((item, i) => {
            if (!item.label) {
              this.feeList?.splice(i, 1)
            } else if (index !== i && item.isEditable === true) {
              this.saveFee(item, i)
            }
          })
        }

        const fee = this.feeList[index]
        if (fee) {
          if (state === 'isEditable' && !fee.isEditable) {
            fee.isEditable = true
          } else if (state === 'isNonEditable' && fee.isEditable) {
            fee.isEditable = false
            this.saveFee(fee, index)
          }
          this.feeList.splice(index, 1, fee)
        }
      }
    },
    saveFee(fee: Fee, index: number) {
      if (!fee.label) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Veuillez renseigner le nom du frais.',
        })

        return
      }
      fee.inventoryId = this.inventory?.id
      const data = {
        ...fee,
      }

      let method = 'post'
      let url = `/fee/inventory/${this.inventory?.id}`
      if (fee.id) {
        method = 'put'
        url = `/fee/${fee.id}/inventory/${this.inventory?.id}`
      }

      this.$api
        .request({
          method,
          url,
          data,
        } as AxiosRequestConfig)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          const updatedFee = apiResponse.data.fee
          updatedFee.isEditable = false
          this.feeList.splice(index, 1, updatedFee)
          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Opération réalisée avec succès',
          })
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
    deleteFee(index: number, feeId: number) {
      if (!feeId) {
        this.feeList?.splice(index, 1)
      } else {
        this.$confirm(
          'Êtes-vous sûr(e) de vouloir supprimer cette ligne de facture ? Attention, cette opération est irréversible.',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        )
          .then(() => {
            this.busy = true
            this.$api.delete(`/fee/${feeId}/inventory/${this.inventory?.id}`).then(() => {
              this.feeList?.splice(index, 1)
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Opération réalisée avec succès !',
              })
            })
          })
          .catch((error) => {
            if (error !== 'cancel') {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    generateRequest() {
      this.busy = true

      const loading = this.$loading({
        target: '#container',
        text: 'Chargement des données...',
      })

      this.$api
        .post(`/document/${this.inventory?.id}/evaluation`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          const size = apiResponse.data.size
          const time = apiResponse.data.time

          this.$emit('updateDocuments', apiResponse.data.documents)

          this.$notify({
            type: 'success',
            title: 'Succès',
            message: `La requête en taxation d'honoraires a été créée avec succès en ${time} secondes et ${size} Mo ! Elle est disponible en téléchargement dans l'onglet Documents.`,
          })
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          loading.close()
        })
    },
    createInvoice() {
      const dueDate = new Date()
      dueDate.setDate(dueDate.getDate() + this.duePeriod)
      const invoiceData = {
        contact: this.inventory?.requerant,
        invoicingDate: new Date(),
        dueDate: dueDate,
        state: 'proforma',
        paymentState: 'unpaid',
        type: 'inventaire',
        items: this.feeList?.filter((item) => item.label !== null),
        projectId: this.project?.id,
        structureId: this.projectStructure?.id,
      } as Invoice

      const data = {
        ...invoiceData,
      }

      this.$api
        .post('/invoice', data)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.billingData?.invoices.unshift(apiResponse.data.invoice)

          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Opération réalisée avec succès',
          })
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
    getCountDetail() {
      this.tableData = [] as Record<string, string>[]
      this.$api.get(`/emoluments/${this.$route.params.inventoryId}`).then((response) => {
        const apiResponse = response.data as ApiResponse

        this.emoluments = apiResponse.data

        this.emoluments.items?.forEach((item: EmolumentItem) => {
          const emolument = this.billingData.method ? item[this.billingData.method] : item.average
          this.tableData.push({
            col1: this.formatCurrency(emolument.value),
            col2: this.formatCurrency(emolument.emoluments[0], true),
            col3: this.formatCurrency(emolument.emoluments[1], true),
            col4: this.formatCurrency(emolument.emoluments[2], true),
            col5: this.formatCurrency(emolument.emoluments[3], true),
          })
        })

        this.showModal = true
      })
    },
    getSummaries(param: Record<string, string[]>) {
      const { columns } = param
      const sums = [] as string[]
      const total = this.emoluments.total as EmolumentItem

      columns.forEach((column: string, index: number) => {
        const totalEmolument = this.billingData.method ? total[this.billingData.method] : total.average
        if (index === 0) {
          sums[index] = 'Totaux'
          return
        } else if (index === 1) {
          sums[index] = this.formatCurrency(totalEmolument.value)
          return
        } else {
          sums[index] = this.formatCurrency(totalEmolument.emoluments[index - 2])
        }
      })

      return sums
    },
    getLimit(index: number) {
      let lowLimit = '0'
      let highLimit = '0'
      let rate = ''

      if (this.emoluments.config) {
        lowLimit = index < 2 ? '0' : this.formatCurrency(this.emoluments.config[index - 2]?.limit)
        highLimit = this.formatCurrency(this.emoluments.config[index - 1]?.limit)
        rate = `${(this.emoluments.config[index - 1]?.taux * 100).toString()}%`
      }

      if (index > 3) {
        return `Au dessus de ${lowLimit} (${rate})`
      } else {
        return `De ${lowLimit} à ${highLimit} (${rate})`
      }
    },
    getIndex(scope: Record<string, number>) {
      return scope.$index + 1
    },
    loadAllProducts() {
      this.products = []
      this.$api
        .get('/products', {
          params: {
            page: 'inventoryBilling',
            structure: this.projectStructure?.id,
            type: 'inventory',
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          const products = apiResponse.data

          if (products && Array.isArray(products)) {
            this.products = products
          }
        })
    },
    loadProductInfo(index: number, searchProductId: number) {
      const selectedProduct = this.products.find((product) => product.id === searchProductId)
      if (selectedProduct !== undefined) {
        const feesLength = this.feeList?.length ?? 0
        const fee = {
          label: selectedProduct?.name,
          type: selectedProduct?.type ?? null,
          quantity: 1,
          priceExcludingTaxes: selectedProduct?.unitPriceExcludingTaxes,
          tvaRate: selectedProduct?.tvaRate,
          accountingAccount: selectedProduct?.accountingAccount,
          isEditable: true,
          position: feesLength + 1,
          opaque: selectedProduct?.opaque,
        } as Fee
        this.feeList?.splice(index, 1, fee)
      }
    },
    clearFee(index: number) {
      const clearedFee: Fee = this.feeList ? this.feeList[index] : {}
      clearedFee.label = ''
      clearedFee.isEditable = true
      this.feeList?.splice(index, 1, clearedFee)
    },
    // force la méthode de calcul et bloque le select
    checkNature() {
      if (this.inventory?.nature == 'LJ' || this.inventory?.nature == 'LS') {
        this.billingData.method = 'realisation'
        return true
      }
      if (this.inventory?.nature == 'RJ' || this.inventory?.nature == 'SDJ') {
        this.billingData.method = 'average'
        return true
      }
      return false
    },
  },
})
